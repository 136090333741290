import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/angular";

export const environment = {
  stripeKey: "pk_live_Ae4cs01gEzP5IHdLuKN0VxZh00Sn4WcnIj",
  pusher: {
    key: "c12354c48d5a9b2ccb8c",
    cluster: 'eu',
  },
  firebaseConfig: {
    apiKey: "AIzaSyDswyPQb8Wvr1Ud1G-aIeYua_H7JswNMe0",
    authDomain: "unplan-2a7d9.firebaseapp.com",
    projectId: "unplan-2a7d9",
    storageBucket: "unplan-2a7d9.appspot.com",
    messagingSenderId: "361940899965",
    appId: "1:361940899965:web:1790c00f5235499b78b914",
    measurementId: "G-2SBW6WG637"
  },
  clientURL: "https://fukuokahangout.com",
  notificationURL: "https://app.fukuokahangout.com",
  deeplinkDomain: "fukuokahangout.com",
  brand: "fukuokahangout.com",
  production: true,
  remoteData: true,
  facebook:'546151732630843',
  google: {
    client_id: '62328326527-mp5794b5s0b6ociu00kcnlaar5pt3nav.apps.googleusercontent.com',
    client_secret: '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'
  },
  SERVER_ADDRESS: "https://www.api-prod.mugma.co",
  API_BASE_URL: 'api/',
  getBaseAddress: () => {
    return environment.SERVER_ADDRESS + "/" + environment.API_BASE_URL;
  },
  //onesignal
  onesignalConfig: {
    appId: "6b4caf5a-4de4-45b9-83dd-d83d5bbc2902",
    safari_web_id: "web.onesignal.auto.3c5e9739-5d2f-4f69-94b2-78aed3043174",
    subdomainName: "unplan",
    path: 'assets/OneSignalSDKWorker.js',
  },
  sentryConfig: {
    dsn: "https://e569e23a1df14fdb87ad9dab7993ec6c@o1001431.ingest.sentry.io/5960966" ,
    environment: "production",
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Integrations.BrowserTracing({
        tracingOrigins: ['https://app.unplan.me', 'https://remotepeople.club/api/'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    }
};