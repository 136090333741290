import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss'],
})
export class InstructionsComponent implements OnInit {
  
  @Input() titleInstructions: string;
  @Input() descriptionInstructions: string;

  constructor() {
   }

  ngOnInit() {}
}
