import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Platform, IonRouterOutlet, ModalController, ToastController} from '@ionic/angular';
import { SizeErrorPage } from './size-error/size-error.page';
import { DataService } from './services/data.service';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { environment } from 'src/environments/environment';
import * as Sentry from "@sentry/angular";
import OneSignal from 'onesignal-cordova-plugin';
import { App, BackButtonListener, BackButtonListenerEvent, URLOpenListenerEvent} from '@capacitor/app';
import { RedirectsService } from './services/redirects.service';
import { Router } from '@angular/router';
import { Keyboard } from '@capacitor/keyboard';
import { Network, NetworkPlugin } from '@capacitor/network'; 

const app = initializeApp(environment.firebaseConfig);
const analytics = getAnalytics(app);

Sentry.init(environment.sentryConfig);

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild(IonRouterOutlet, { static : true }) routerOutlet: IonRouterOutlet;

  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  isStatusBarLight = true;
  next: string;
  public hideBrowserAppBanner: boolean = false;

  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private dataService: DataService,
    private zone: NgZone,
    private redirectsService: RedirectsService,
    private router: Router,
    private toastController: ToastController
  ) {
      this.dataService.currentMessage16.subscribe(message16 => this.next = message16);
  }

  ngOnInit() {
    this.networkListener();
    //onesignal
    if(!this.platform.is('mobileweb') && (this.platform.is('ios') || this.platform.is('android'))) {
      this.addMobileListeners(); //listener for URL
      OneSignal.setLogLevel(6, 0);
      OneSignal.setAppId("6b4caf5a-4de4-45b9-83dd-d83d5bbc2902");
      OneSignal.setNotificationOpenedHandler((data)=> {
          if (data.notification.launchURL.includes(environment.notificationURL + "/j/")) {
            const slug = data.notification.launchURL.split("/j/")[1];
            this.router.navigateByUrl("j/" + slug);
          }
      })
    }

    //else
      //this.checkPlatformResize();
      //this.checkPlatform();
    //backbutton android
    if (this.platform.is('android')) {
      App.addListener('backButton', (event: BackButtonListenerEvent) => {
        this.platform.backButton.subscribeWithPriority(9999, () => {
          App.exitApp();
        });
      })
    }
  }

  async toastNetworkError() {
    const toastGeolocationError = await this.toastController.create({
      position: 'bottom',
      message: "You're not connected to the network. Please connect!",
      animated: true,
      duration: 5000,
      cssClass: 'toast-danger'
    });
    await toastGeolocationError.present();
  }

  async networkListener() {
    Network.addListener('networkStatusChange', (status) => {
      console.log(status);
      if (!status.connected) {
        this.toastNetworkError()
      }
    });
    await Network.getStatus();
  }

  addMobileListeners() {

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        if (event.url.includes(environment.deeplinkDomain) && !event.url.includes("/j/")) {
          this.redirectsService.redirectToMain();
        }

        else if (event.url.includes(environment.deeplinkDomain + '/j/')) {
          const slug = event.url.split("/j/")[1];
          this.router.navigateByUrl("j/" + slug)  
        }

      });
    });
  }

  checkPlatform() {
      if (this.platform.width() >= 1600 || this.platform.height() >= 1600) {
        this.showSizeError();
      }
      else this.modalController.dismiss();
  }

  checkPlatformResize() {
    this.platform.resize.subscribe(async () => {
      if (this.platform.width() >= 1600 || this.platform.height() >= 1600) {
        this.showSizeError()
      }
      else this.modalController.dismiss();
    }); 
  }

  async showSizeError() {
    const modal = await this.modalController.create({
      component: SizeErrorPage,
      cssClass: 'size-error',
      animated: false,
    });
    return await modal.present();
  }
}