import { Component } from '@angular/core';

@Component({
  selector: 'brand-big',
  templateUrl: './brand-big.component.html',
  styleUrls: ['./brand-big.component.scss'],
})
export class BrandBigComponent {

  constructor(
  ) { }

}
