import { Injectable } from '@angular/core';
import { RedirectsService } from './redirects.service';

@Injectable()
export class AuthService {
  constructor(
    private redirectsService: RedirectsService
  ) {

  }
  // ...
  public isAuthenticated(): boolean {
    if (localStorage.getItem("access_token") != null) {
      return true;
    }

    else
      return false;
  }
}