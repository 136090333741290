import { Component, Input} from '@angular/core';

@Component({
  selector: 'buttonlargeinvite',
  templateUrl: './button-large-invite.component.html',
  styleUrls: ['./button-large-invite.component.scss'],
})
export class ButtonLargeInviteComponent{

  @Input() inviteCode: string;

  constructor() { }

}
