import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Activity } from '../models/activity.model';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { ToastController } from '@ionic/angular';
import { Plugins } from '@capacitor/core';

const { Clipboard } = Plugins;

@Injectable({
  providedIn: 'root'
})

export class ActivitiesService {

  token: any;
  name: any;
  started_by: any;

  activities: Activity[];

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastController: ToastController
    ) { }

  create(data) {
  //we need to add this.httpoptions
	return this.http.post(environment.getBaseAddress() + 'activities/create', data, {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin': '*',	
      'Authorization': 'Bearer '+ localStorage.getItem("access_token")
    })
  })
  }

  getActivities(): Observable<Activity[]> {
      return this.http.get<Activity[]>(environment.getBaseAddress() + 'activities/get', {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Access-Control-Allow-Origin': '*',	
          'Authorization': 'Bearer '+ localStorage.getItem("access_token")
        })
      })
  }

  getTypeOfActivities() {
    return this.http.get(environment.getBaseAddress() + 'activities/getType', {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',	
        'Authorization': 'Bearer '+ localStorage.getItem("access_token")
      })
    })
}

  getUserActivity(data): Observable<any> {
    return this.http.post<Activity[]>(environment.getBaseAddress() + 'activities/getUserActivity', data, {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("access_token")
      })
    })
  }

  getDetails (data): Observable<any> {
    return this.http.post<Activity[]>(environment.getBaseAddress() + 'activities/getDetails', data, {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',	
        'Authorization': 'Bearer '+ localStorage.getItem("access_token")
      })
    })
  }

  joinActivity (data) {
    return this.http.post(environment.getBaseAddress() + 'activities/join', data, {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',	
        'Authorization': 'Bearer '+ localStorage.getItem("access_token")
      })
    })
  }

  leaveActivity () {
    return this.http.get(environment.getBaseAddress() + 'activities/leave', {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',	
        'Authorization': 'Bearer '+ localStorage.getItem("access_token")
      })
    })
  }

  getCurrentActivityDetails () {
    return this.http.get<Activity[]>(environment.getBaseAddress() + 'activities/currentActivity', {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',	
        'Authorization': 'Bearer '+ localStorage.getItem("access_token")
      })
    })
  }

  reportActivity(data) {
    this.http.post(environment.getBaseAddress() + 'report/activityReport', data, { headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("access_token")
    })}).subscribe((data) =>  {
      this.router.navigateByUrl('main');
    }, 
    () => {
      this.router.navigateByUrl('');
    });
  }

  checkActivitiesLeft() {
    return this.http.get(environment.getBaseAddress() + 'activities/checkMax', {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',	
        'Authorization': 'Bearer '+ localStorage.getItem("access_token")
      })
    })
  }

  async toastActivityCreated(data) {
    const toast = await this.toastController.create({
      header: 'You just created an activity! 🎉',
      message: "Share your URL with others" ,
      position: 'bottom',
      duration: 10000,
      buttons : [{
          text: 'Copy',
          handler: () => {
            this.clipboard(data);
          }
        }
      ]
    });
    await toast.present();
  }

  async toastActivityJoined(data) {
    const toast = await this.toastController.create({
      header: 'You just joined an activity! 🎉',
      message: "Share your URL with others" ,
      position: 'bottom',
      duration: 10000,
      buttons : [{
          text: 'Copy',
          handler: () => {
            this.clipboard(data);
          }
        }
      ]
    });
    await toast.present();
  }

  async clipboard(data) {
    Clipboard.write({
      string: environment.clientURL + data,
    });

    let result = await Clipboard.read();
    console.log('Got', result.type, 'from clipboard:', result.value);
   }

}