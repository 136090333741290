import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private messageSource = new BehaviorSubject(''); //activity_type
  currentMessage = this.messageSource.asObservable(); 

  private messageSource2 = new BehaviorSubject(''); //place_id
  currentMessage2 = this.messageSource2.asObservable();

  private messageSource3 = new BehaviorSubject(''); //main_location
  currentMessage3 = this.messageSource3.asObservable();

  private messageSource4 = new BehaviorSubject(''); //how_recognize
  currentMessage4 = this.messageSource4.asObservable();

  private messageSource5 = new BehaviorSubject(''); //access_token
  currentMessage5 = this.messageSource5.asObservable();

  private messageSource6 = new BehaviorSubject(''); //reported_activity
  currentMessage6 = this.messageSource6.asObservable();

  private messageSource7 = new BehaviorSubject(''); //activity_photo
  currentMessage7 = this.messageSource7.asObservable(); 

  private messageSource8 = new BehaviorSubject(false); //CurrentActivityPresent
  currentMessage8 = this.messageSource8.asObservable();

  private messageSource9 = new BehaviorSubject(''); //profile_picture
  currentMessage9 = this.messageSource9.asObservable();

  private messageSource10 = new BehaviorSubject(''); //name
  currentMessage10 = this.messageSource10.asObservable();

  private messageSource11 = new BehaviorSubject(''); //job title
  currentMessage11 = this.messageSource11.asObservable();

  private messageSource12 = new BehaviorSubject(false); //reload activities
  currentMessage12 = this.messageSource12.asObservable();

  private messageSource13 = new BehaviorSubject(false); //scrollMessages
  currentMessage13 = this.messageSource13.asObservable();

  private messageSource14 = new BehaviorSubject(false); //myProfile
  currentMessage14 = this.messageSource14.asObservable();

  private messageSource15 = new BehaviorSubject(''); //myProfile
  currentMessage15 = this.messageSource15.asObservable();

  private messageSource16 = new BehaviorSubject(''); //myProfile
  currentMessage16 = this.messageSource16.asObservable();

  private messageSource17 = new BehaviorSubject(null); //community LatLng
  currentMessage17 = this.messageSource17.asObservable();

  private messageSource18 = new BehaviorSubject(''); //activityParam
  currentMessage18 = this.messageSource18.asObservable();

  private messageSource19 = new BehaviorSubject(''); //activityChat
  currentMessage19 = this.messageSource19.asObservable();

  private messageSource20 = new BehaviorSubject(''); //currentChatUser
  currentMessage20 = this.messageSource20.asObservable();

  private messageSource21 = new BehaviorSubject(''); //inviteCode
  currentMessage21 = this.messageSource21.asObservable();

  private messageSource22 = new BehaviorSubject(''); //email
  currentMessage22 = this.messageSource22.asObservable();

  private messageSource23 = new BehaviorSubject(''); //accountType
  currentMessage23 = this.messageSource23.asObservable();

  private messageSource24 = new BehaviorSubject(''); //phoneNumber
  currentMessage24 = this.messageSource24.asObservable();

  private messageSource25 = new BehaviorSubject(''); //initialtype of user when he/she logs in
  currentMessage25 = this.messageSource25.asObservable();

  private messageSource26 = new BehaviorSubject(''); //platform
  currentMessage26 = this.messageSource26.asObservable();

  private messageSource27 = new BehaviorSubject([]); //platform
  currentMessage27 = this.messageSource26.asObservable();

  private messageSource28 = new BehaviorSubject(false); //clickedCheckIn
  currentMessage28 = this.messageSource28.asObservable();

  private messageSource29 = new BehaviorSubject(''); //selectedTypeOfActivity
  currentMessage29 = this.messageSource29.asObservable();

  private messageSource30 = new BehaviorSubject(null); //activityEmoji
  currentMessage30 = this.messageSource30.asObservable();

  private messageSource38 = new BehaviorSubject(false); //canceledActivity
  currentMessage38 = this.messageSource38.asObservable();

  private messageSource83 = new BehaviorSubject(false); //has an activity with at least one participant
  currentMessage83 = this.messageSource83.asObservable();

  private messageSource35 = new BehaviorSubject(false); //listening for someone to join
  currentMessage35 = this.messageSource35.asObservable();

  private messageSource36 = new BehaviorSubject(''); //selectedPlaceId
  currentMessage36 = this.messageSource36.asObservable();

  private messageSource37 = new BehaviorSubject(''); //destinationPlaceId
  currentMessage37 = this.messageSource37.asObservable();

  private messageSource39 = new BehaviorSubject(null); //destinationPlaceId
  currentMessage39 = this.messageSource39.asObservable();

  private messageSource40 = new BehaviorSubject(null); //urlBeforePreferences
  currentMessage40 = this.messageSource40.asObservable();

  private messageSource41 = new BehaviorSubject([]); //lastCalledPhotos
  currentMessage41 = this.messageSource41.asObservable();

  private messageSource42 = new BehaviorSubject(null); //lastCalledPlaceId
  currentMessage42 = this.messageSource42.asObservable();

  private messageSource43 = new BehaviorSubject(false); //getPlaces
  currentMessage43 = this.messageSource43.asObservable();

  constructor() { }

  updateActivityType (message: string) {
    this.messageSource.next(message)
  }

  updateActivityPlaceID (message2: string) {
    this.messageSource2.next(message2)
  }

  updateActivityMainLocation (message3: string) {
    this.messageSource3.next(message3)
  }

  updateActivityHowRecognize (message4: string) {
    this.messageSource4.next(message4)
  }

  updateAccessToken (message5: string) {
    this.messageSource5.next(message5)
  }

  updateReportedActivity (message6: string) {
    this.messageSource6.next(message6)
  }

  updateActivityUnsplashPhoto (message7: string) {
    this.messageSource7.next(message7)
  }

  hasCurrentActivity(message8: boolean) {
    this.messageSource8.next(message8);
  }

  hasCurrentActivityWithParticipants(message83: boolean) {
    this.messageSource83.next(message83);
  }

  updateProfilePicture(message9: string) {
    this.messageSource9.next(message9)
  }

  updateName(message10: string) {
    this.messageSource10.next(message10)
  }

  updateJobTitle(message11: string) {
    this.messageSource11.next(message11)
  }

  reloadActivities(message12: boolean) {
    this.messageSource12.next(message12)
  }

  scrollMessages(message13: boolean) {
    this.messageSource13.next(message13)
  }

  myProfile(message14: boolean) {
    this.messageSource14.next(message14)
  }

  myProfileRoute(message15: string) {
    this.messageSource15.next(message15)
  }

  next(message16: string) {
    this.messageSource16.next(message16)
  }

  communityLatLng(message17: {}) {
    this.messageSource17.next(message17)
  }

  activityParam(message18: string) {
    this.messageSource18.next(message18)
  }

  activityChat(message19: string) {
    this.messageSource19.next(message19)
  }

  currentChatUser(message20: string) {
    this.messageSource20.next(message20)
  }

  inviteCode(message21: string) {
    this.messageSource21.next(message21)
  }

  email(message22: string) {
    this.messageSource22.next(message22)
  }

  accountType(message23: string) {
    this.messageSource23.next(message23);
  }

  phoneNumber(message24: string) {
    this.messageSource24.next(message24);
  }

  initialUserType(message25: string) {
    this.messageSource25.next(message25);
  }

  platform(message26: string) {
    this.messageSource26.next(message26);
  }

  circles(message27: []) {
    this.messageSource27.next(message27);
  }

  checkInClick(message28: boolean) {
    this.messageSource28.next(message28);
  }

  activityEmoji(message30: string) {
    this.messageSource30.next(message30)
  }

  cancelActivity(message38: boolean) {
    this.messageSource38.next(message38);
  }

  listenForActivityParticipantsForLoad(message35: boolean) {
    this.messageSource35.next(message35);
  }

  selectedPlaceId(message36: string) {
    this.messageSource36.next(message36);
  }

  selectedTypeOfActivity(message29: string) {
    this.messageSource29.next(message29);
  }

  destinationPlaceId(message37: string) {
    this.messageSource37.next(message37);
  }

  updateActivityName(message39: string) {
    this.messageSource39.next(message39)
  }

  setURLbeforePreferences(message40: string) {
    this.messageSource40.next(message40)
  }

  lastCalledPhotos(message41: string[]) {
    this.messageSource41.next(message41)
  }

  lastCalledPlaceId(message42: string) {
    this.messageSource42.next(message42);
  }

  getPlaces(message43: boolean) {
    this.messageSource43.next(message43);
  }

}