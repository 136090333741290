import { Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-modal-pay',
  templateUrl: './modal-pay.component.html',
  styleUrls: ['./modal-pay.component.scss'],
})
export class ModalPayComponent {

  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  closeModal() {
    this.close.emit();
  }

}
