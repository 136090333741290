import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Place } from '../models/place.model';

@Injectable({
  providedIn: 'root'
})
export class PlacesService {
 
  
  constructor(private http: HttpClient) { 
   }

  create(data) {
    return this.http.post(environment.getBaseAddress() + 'places/create', data, {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+localStorage.getItem("access_token")
      })
    })
  }

  getPlaces () {
    return this.http.get<Place[]>(environment.getBaseAddress() + 'places/get');
  }

  getNearbyPlaces(data) {
    return this.http.post(environment.getBaseAddress() + 'places/getPlaces', data, {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+ localStorage.getItem("access_token")
      })
    });
  }

  getPhotoPlaces(placeId: string) { //from google places api
    const service = new google.maps.places.PlacesService(document.createElement('div'))

    const request = {
      placeId: placeId,
      fields: ['photos'],
    };

    return service.getDetails(request, callback => {
      callback.photos.forEach(photo=> {
      })
    });
  }

  addPlaceToFavorites(placeId) {
    return this.http.post(environment.getBaseAddress() + 'places/favorites/add', placeId, {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+ localStorage.getItem("access_token")
      })
    });
  }

  removePlaceFromFavorites(placeId) {
    return this.http.post(environment.getBaseAddress() + 'places/favorites/delete', placeId, {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+ localStorage.getItem("access_token")
      })
    });
  }

  getFavoriteStatus(placeId) {
    return this.http.post(environment.getBaseAddress() + 'places/favorites/get', placeId, {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+ localStorage.getItem("access_token")
      })
    });
  }

}