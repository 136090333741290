import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/unplan/unplan.module').then( m => m.UnplanPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'j/:url_param',
    loadChildren: () => import('./pages/activity-details/activity-details.module').then( m => m.ActivityDetailsPageModule),
  },
  {
    path: 'current-activity',
    loadChildren: () => import('./pages/current-activity/current-activity.module').then( m => m.CurrentActivityPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'size-error',
    loadChildren: () => import('./size-error/size-error.module').then( m => m.SizeErrorPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'waitlist',
    loadChildren: () => import('./waitlist/waitlist.module').then( m => m.WaitlistPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'phone-number',
    loadChildren: () => import('./pages/phone-number/phone-number.module').then( m => m.PhoneNumberPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'create-community',
    loadChildren: () => import('./pages/create-community/create-community.module').then( m => m.CreateCommunityPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'preferences',
    loadChildren: () => import('./preferences/preferences.module').then( m => m.PreferencesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'subscription',
    loadChildren: () => import('./pages/subscription/subscription.module').then( m => m.SubscriptionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'add-picture',
    loadChildren: () => import('./pages/add-picture/add-picture.module').then( m => m.AddPicturePageModule),
    
  },
  {
    path: 'enable-location',
    loadChildren: () => import('./pages/enable-location/enable-location.module').then( m => m.EnableLocationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ':community',
    loadChildren: () => import('./pages/community/community.module').then( m => m.CommunityPageModule),
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
