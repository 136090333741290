import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss'],
})
export class BrandComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {}

  goToBrand() {
    window.location.href= environment.clientURL;
  }

}
