import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';


@Component({
  selector: 'backtoolbar',
  templateUrl: './backtoolbar.component.html',
  styleUrls: ['./backtoolbar.component.scss'],
})
export class BacktoolbarComponent implements OnInit {

  constructor(private location: Location) { }

  ngOnInit() {}

  back(): void {
    this.location.back()
  }
}