import { Component, Input, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';
import { PlacesService } from 'src/app/services/places.service';
import { RedirectsService } from 'src/app/services/redirects.service';

@Component({
  selector: 'app-modal-activity',
  templateUrl: './modal-activity.component.html',
  styleUrls: ['./modal-activity.component.scss'],
})
export class ModalActivityComponent {

  @ViewChild('slides', { static: false }) slides: IonSlides;

  photos = [];
  favorite: boolean;
  buttonDisabled = false;
  lastCalledPlaceId: string;
  lastCalledPhotos: string[];
  ready = false;

  slideOptions = {
    initialSlide: 0,
    slidesPerView: 1,
    preloadImages: true,
    lazy: false,
  };

  constructor(
    private dataService: DataService,
    private redirectsService: RedirectsService,
    private modalController: ModalController,
    private placesService: PlacesService
  ) { 
    this.dataService.currentMessage41.subscribe(
      (message41) => (this.lastCalledPhotos = message41)
    );

    this.dataService.currentMessage42.subscribe(
      (message42) => (this.lastCalledPlaceId = message42)
    );
  }

  @Input() activityEmoji: string;
  @Input() placeId: string;
  @Input() typeOfActivityId: string;
  @Input() nameActivity: string;
  @Input() placeName: string;
  @Input() placeAddress: string;
  @Input() communityName: string;
  @Input() communityUrl: string;
  @Input() communityLogo: string;

  async ngOnInit() {
    if (this.placeId == this.lastCalledPlaceId) {
      this.photos = this.lastCalledPhotos;
    } else {
      try {
        const photos = await this.getPlacePhotos(this.placeId);
        this.photos = photos.slice(0, 3); // Limit to 3 photos
        this.dataService.lastCalledPhotos(this.photos);
        this.dataService.lastCalledPlaceId(this.placeId);
      } catch (error) {
        console.error('Error fetching photos:', error);
      }
    }

    this.getFavoriteStatus()
  }

  async getPlacePhotos(placeId: string): Promise<string[]> {
    const service = new google.maps.places.PlacesService(document.createElement('div'));
    const request = {
      placeId: placeId,
      fields: ['photos'],
    };

    return new Promise<string[]>((resolve, reject) => {
      service.getDetails(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place && place.photos) {
          const photos = place.photos.map(photo => photo.getUrl({ maxHeight: 300 }));
          resolve(photos);
        } else {
          reject(status);
        }
      });
    });
  }

  checkIn() {
    this.buttonDisabled = true;
    this.dataService.selectedPlaceId(this.placeId);
    this.dataService.selectedTypeOfActivity(this.typeOfActivityId);
    const a = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.dataService.checkInClick(true));
      }, 500);
    });
  }

  redirect(communityUrl) {
    if (communityUrl != null) {
      this.redirectsService.goToCommunityUrl(communityUrl);
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  async addToFavorites() {
    const request = {
      place_id: this.placeId
    }

    try {
      await this.placesService.addPlaceToFavorites(request);
      this.favorite = true;
    } catch (error) {
      console.error('Error adding to favorites:', error);
    }
  }

  async removeFromFavorites() {
    const request = {
      place_id: this.placeId
    };

    try {
      await this.placesService.removePlaceFromFavorites(request);
      this.favorite = false;
    } catch (error) {
      console.error('Error removing from favorites:', error);
    }
  }

  async getFavoriteStatus() {
    const request = {
      place_id: this.placeId
    };

    try {
      const data = await this.placesService.getFavoriteStatus(request);
      if (data['status'] == true) {
        this.favorite = true;
      } else if (data['status'] == false) {
        this.favorite = false;
      }
    } catch (error) {
      console.error('Error fetching favorite status:', error);
    }
  }

  imagesLoaded(e) {
    setTimeout(() => {
      this.ready = true;
    }, 100);
  }
}