import { Injectable } from '@angular/core';
import { Clipboard } from '@capacitor/clipboard';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  invite_url;
  user_invite_body = environment.clientURL + '/login?invite=';
  community_invite_body = environment.clientURL + "/";
  activity_invite_body = environment.clientURL + "/j/";
  
  constructor() { }


  copyActivityCode(data) {
    let val = this.activity_invite_body + data;
    Clipboard.write({
      string: val
    })
    data = "copied!";
    return data;
  }

  copyInviteCode(data) {
    let val = this.user_invite_body + data;
    Clipboard.write({
      string: val
    })
    data = "copied!";
    return data;
  }

  copyCommunityInviteCode(data) {
    let val = this.community_invite_body + data;
    Clipboard.write({
      string: val
    })
    data = "copied!";
    return data;
  }

  randomizeEmoji() {
    var colors = ["🎉","😀","💃","🥳", "☕"];
    var randColor = colors[Math.floor(Math.random() * colors.length)];
    return randColor;
  }
}
