import { NgModule } from '@angular/core';
import { BacktoolbarComponent } from './backtoolbar/backtoolbar.component';
import { InstructionsComponent } from './instructions/instructions.component';
import { BrandComponent } from './brand/brand.component';
import { ButtonLargeInviteComponent } from './button-large-invite/button-large-invite.component';
import { LoginbuttonComponent } from './loginbutton/loginbutton.component';
import { NextbuttonComponent } from './nextbutton/nextbutton.component';
import { CommonModule } from '@angular/common';
import { CheckInButtonComponent } from './check-in-button/check-in-button.component';
import { BrandBigComponent } from './brand-big/brand-big.component';
import { ModalActivityComponent } from './modal-activity/modal-activity.component';
import { ModalPayComponent } from './modal-pay/modal-pay.component';
import { ButtonPrimaryComponent } from './button-primary/button-primary.component';
import { ButtonSecondaryComponent } from './button-secondary/button-secondary.component';
import { CloseButtonComponent } from './close-button/close-button.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    InstructionsComponent,
    BacktoolbarComponent,
    BrandComponent,
    ButtonLargeInviteComponent,
    LoginbuttonComponent,
    NextbuttonComponent,
    CheckInButtonComponent,
    BrandBigComponent,
    ModalActivityComponent,
    ModalPayComponent,
    ButtonPrimaryComponent,
    ButtonSecondaryComponent,
    CloseButtonComponent
  ],
  exports: [
    InstructionsComponent,
    BacktoolbarComponent,
    BrandComponent,
    ButtonLargeInviteComponent,
    LoginbuttonComponent,
    NextbuttonComponent,
    CheckInButtonComponent,
    BrandBigComponent,
    ModalActivityComponent,
    ModalPayComponent,
    ButtonPrimaryComponent,
    ButtonSecondaryComponent,
    CloseButtonComponent
  ]
})

export class ComponentsModule{}