import { Injectable } from '@angular/core';
import { Router} from '@angular/router';
import { Browser } from '@capacitor/browser';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RedirectsService {

  myProfileRoute: string;

  constructor(
    private router: Router,
    private dataService: DataService
  ) {
    this.dataService.currentMessage15.subscribe(message15 => this.myProfileRoute = message15);
  }

  async goToPrivacy() {
    await Browser.open({ url: 'https://www.notion.so/Privacy-Notice-8e79660676584ec184e63c2233fa32fb', presentationStyle: 'popover'});
  }

  async goToCommunityUrl(url) {
    await Browser.open({ url: url, presentationStyle: 'popover'});
  }

  async goToHelp() {
    await Browser.open({ url: "https://www.notion.so/Support-ea3edf3a9dbf43a0a1094809cce427fc", presentationStyle: 'popover'});
  }

  async goToReleaseNotes() {
  await Browser.open({ url: "https://www.notion.so/RemotePeople-Release-Notes-fc3681b461f14093b7cfe5be0a71efee", presentationStyle: 'popover'});
  }

  async goToInstagram() {
    await Browser.open({ url: "https://instagram.com/" + environment.brand, presentationStyle: 'popover'});
  }

  async goToMap(data) {
    await Browser.open({ url: "https://www.google.com/maps/place/?q=place_id:" + data, presentationStyle: 'popover'});
  }

  async goToSupport() {
    await Browser.open({ url: 'https://elastic-wash-3ef.notion.site/Support-ea3edf3a9dbf43a0a1094809cce427fc', presentationStyle: 'popover'});
  }

  async goToAppIOS() {
    await Browser.open({ url: 'https://apps.apple.com/us/app/unplan-me/id1597576712', presentationStyle: 'popover'});
  }

  async goToAppAndroid() {
    await Browser.open({ url: 'https://play.google.com/store/apps/details?id=com.unplan&hl=en_CA&gl=US', presentationStyle: 'popover'});
  }

  redirect401(data) {
    if (data.status === 401) {
      this.router.navigateByUrl("login");
    } 
  }

  redirectToProfile() {
    this.router.navigateByUrl('profile');
  }

  async redirectToHomePage() {
    await Browser.open({ url: 'https://' + environment.brand, presentationStyle: 'popover'});
  }

  redirectToEnableLocation() {
    this.router.navigateByUrl('enable-location');
  }

  redirectStart() {
    this.router.navigateByUrl("login");
  }

  redirectToWaitlist() {
    this.router.navigateByUrl('waitlist');
  }
  
  redirectToIntro() {
    this.router.navigateByUrl('intro');
  }

  redirectToPreferences() {
    this.router.navigateByUrl('preferences');
  }

  redirectToCommunityInvite() {
    this.router.navigateByUrl('community-invite');
  }

  redirectToCommunityData() {
    this.router.navigateByUrl("community-data");
  }

  redirectToPlaces() {
    this.router.navigateByUrl("preferred-places");
  }

  redirectToGeolocation() {
    this.router.navigateByUrl("enable-location");
  }

  redirectToMain() {
    this.router.navigateByUrl("");
  }

  redirectToCreateCommunity() {
    this.router.navigateByUrl('create-community');
  }
}
