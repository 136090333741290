import { Component, OnInit } from '@angular/core';
import { ActivitiesService } from 'src/app/services/activities.service';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'checkinbutton',
  templateUrl: './check-in-button.component.html',
  styleUrls: ['./check-in-button.component.scss'],
})
export class CheckInButtonComponent implements OnInit{

  emoji: any;
  text: any;

  constructor(
    private toolsService: ToolsService,
    private activitiesService: ActivitiesService
  ) { }

  ngOnInit() {
    this.text="Tap to meet remote workers near you"
    this.changeEmoji();
  }

  changeEmoji() {
    this.emoji = this.toolsService.randomizeEmoji()
  }

  animateOnClick() {
    this.text = "Looking around you..."
    document.getElementById('circle').classList.add('reduce');
    this.startRadar();
    this.activitiesService.create({type_of_activity: "create"}).subscribe(()=> {
    },
    () => {
    })
  }

  startRadar() {
    document.getElementById('circle').classList.add('radar');
  }

}
