import { CUSTOM_ELEMENTS_SCHEMA, NgModule, ErrorHandler } from '@angular/core';
import * as Sentry from "@sentry/angular";
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router} from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SignInWithApple } from "@awesome-cordova-plugins/sign-in-with-apple/ngx";
import { IonicStorageModule } from '@ionic/storage';
import { ComponentsModule } from './components/components.module';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { SizeErrorPageModule } from './size-error/size-error.module';
import { SizeErrorPage } from './size-error/size-error.page';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { environment } from 'src/environments/environment';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFireModule } from '@angular/fire/compat';

@NgModule({
  declarations: [
    AppComponent,
    SizeErrorPage
  ],
  imports: [
    ComponentsModule,
    BrowserModule, 
    IonicModule.forRoot({swipeBackEnabled: false}),
    AppRoutingModule, 
    HttpClientModule, 
    IonicModule, 
    ReactiveFormsModule,
    FormsModule,
    SizeErrorPageModule,
    IonicStorageModule.forRoot(),
    SocialLoginModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule
  ],
  providers: [
    Facebook,
    StatusBar,
    SplashScreen,
    SignInWithApple,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    AuthGuardService,
    AuthService,
    Geolocation,
    {
        provide: 'SocialAuthServiceConfig',
        useValue: {
          autoLogin: false,
          providers: [
            {
              id: FacebookLoginProvider.PROVIDER_ID,
              provider: new FacebookLoginProvider(environment.facebook)
            },
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(environment.google.client_id)
            }
          ]
        } as SocialAuthServiceConfig,
    },
  ],
  exports: [
    SizeErrorPage
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}