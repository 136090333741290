import { Component, Input } from '@angular/core';

@Component({
  selector: 'loginbutton',
  templateUrl: './loginbutton.component.html',
  styleUrls: ['./loginbutton.component.scss'],
})
export class LoginbuttonComponent {

  @Input() socialType: string;
  @Input() icon: string;
  @Input() classButton: string;

  constructor() { }

}
